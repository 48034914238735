var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-progress__wrapper" }, [
    _c("div", {
      staticClass: "step-progress__wrapper-before",
      style: {
        "background-color": _vm.passiveColor,
        height: _vm.lineThickness + "px",
      },
    }),
    _c(
      "div",
      { staticClass: "step-progress__bar" },
      _vm._l(_vm.steps, function (step, index) {
        return _c(
          "div",
          {
            staticClass: "step-progress__step",
            class: {
              "step-progress__step--active": index === _vm.currentStep,
              "step-progress__step--valid": index < _vm.currentStep,
            },
            style: {
              "--activeColor": _vm.activeColor,
              "--passiveColor": _vm.passiveColor,
              "--activeBorder": _vm.activeThickness + "px",
              "--passiveBorder": _vm.passiveThickness + "px",
            },
          },
          [
            _c("span", [_vm._v(_vm._s(index + 1))]),
            _c("div", {
              staticClass: "step-progress__step-icon",
              class: _vm.iconClass,
            }),
            _c("div", { staticClass: "step-progress__step-label" }, [
              _vm._v(_vm._s(step)),
            ]),
          ]
        )
      }),
      0
    ),
    _c("div", {
      staticClass: "step-progress__wrapper-after",
      style: {
        transform:
          "scaleX(" + _vm.scaleX + ") translateY(-50%) perspective(1000px)",
        "background-color": _vm.activeColor,
        height: _vm.lineThickness + "px",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }